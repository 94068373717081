// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const checkToday = date => {
  return isToday(new Date(date))
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDTime = (value) => {
  if (!value) return value
  const position = value.indexOf(' ') + 1
  return value.substring(position, position + 5)
}

export const checkImage = (url) => {
  var s = document.createElement("IMG")
  s.src = url
  s.onerror = function () {
    console.log("file with " + url + " invalid")
    return 'https://erp.cloodo.com/img/default-profile-3.png'
  }
  s.onload = function () {
    console.log("file with " + url + " valid")
    return url
  }
}

const stringToColor = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

export const CustomAvatar = ({ name, avatarUrl, size = '42px', align = 'left', margin, fontSize = '16px', height = '100%' }) => {
  const defaultAvatar = 'https://erp.cloodo.com/img/avatar.png';
  const isDefaultAvatar = !avatarUrl || avatarUrl === defaultAvatar;
  const backgroundColor = name ? stringToColor(name) : '#ccc';

  const getInitials = name => {
    if (!name) return '';
    return name.split(' ').map(word => word[0]).join('').toUpperCase();
  };

  const avatarStyle = {
    width: size,
    height: size,
    borderRadius: '50%',
    backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: fontSize,
    color: 'white',
    border: '2px solid #fff'
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: align === 'center' ? 'center' : (align === 'left' ? 'flex-start' : 'flex-end'),
    margin: margin
  };

  return (
    <div style={containerStyle}>
      <div style={avatarStyle}>
        {isDefaultAvatar && name
          ? <span>{getInitials(name)}</span>
          : <img src={avatarUrl} alt="Avatar" style={{ width: '100%', height: height, borderRadius: '50%' }} />}
      </div>
    </div>
  );
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getUid = () => {
  let iuds = getUserData()?.iuds;
  if (iuds) {
    return parseInt(Buffer.from(iuds, 'base64').toString());
  }
  return null;
};
export const getHearderAuth = (includeCompanyToken = true, additionalHeaders = []) => {
  const jwt_user = getUserData()?.data.token;
  const token_company = localStorage?.getItem("token_company");
  const headers = {
    Authorization: `Bearer ${jwt_user}`
  };

  if (includeCompanyToken && token_company) {
    headers["X-WorkSuite-Company-Token"] = token_company;
  }

  // Add additional headers if provided
  additionalHeaders.forEach(header => {
    headers[header.key] = header.value;
  });

  return headers;
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/apps/dashboard/company'
  return '/'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})
