// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { REST_API_WORKSUITE_V3 } from "../../../../../constants";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

// ** token
const user = JSON.parse(localStorage.getItem("userData"));
const token = `Bearer ${user?.data?.token}`;
const token_company = localStorage?.getItem("token_company");

// ** Axios Imports
import axios from "axios";

// export  const labelColor = (color) => {
//   switch (color) {
//     case "bg-info": {
//       return "Business";
//     }
//     case "bg-danger": {
//       return "Personal";
//     }
//     case "bg-warning": {
//       return "Family";
//     }
//     case "bg-success": {
//       return "Holiday";
//     }
//     case "bg-purple": {
//       return "ETC";
//     }
//     default:
//       throw new Error("Invalid");
//   }
// };

export const fetchEvents = createAsyncThunk(
  "appCalendar/fetchEvents",
  async (calendars) => {
    // console.log(calendars);
    // const config = {
    //   method: "get",
    //   url: `${REST_API_WORKSUITE_V3}events?limit=100`,
    //   headers: {
    //     Authorization: token,
    //     "X-WorkSuite-Company-Token": token_company,
    //   },
    //   // data: calendars
    // };
    // const response = await axios(config);
    // console.log(response.data.data);

    // const converData1 = response.data.data.map((item, index) => {
    //   // const labelColor = (item.label_color) => {
    //   //   switch (item.label_color) {
    //   //     case "bg-info": {
    //   //       return "Business";
    //   //     }
    //   //     case "bg-danger": {
    //   //       return "Personal";
    //   //     }
    //   //     case "bg-warning": {
    //   //       return "Family";
    //   //     }
    //   //     case "bg-success": {
    //   //       return "Holiday";
    //   //     }
    //   //     case "bg-purple": {
    //   //       return "ETC";
    //   //     }
    //   //     default:
    //   //       throw new Error("Invalid");
    //   //   }
    //   // };
    //   // return {
    //   //   id: item.id,
    //   //   url: "",
    //   //   title: item.event_name,
    //   //   start: item.start_date_time,
    //   //   end: item.end_date_time,
    //   //   allDay: false,
    //   //   extendedProps: {
    //   //     calendar: "Business",
    //   //   },
    //   // };
    //   return {
    //     id: 1,
    //     url: "",
    //     title: "Meeting",
    //     start: "2024-03-29T10:06:24.590Z",
    //     end: "2024-03-30T10:06:24.590Z",
    //     allDay: false,
    //     extendedProps: {
    //         calendar: "Business"
    //     }
    // }
    // });
    // console.log(converData1);
    // const response = await axios.get('/apps/calendar/events',{calendars})
    const date = new Date()
    const newDate =  new Date(date.getFullYear(), date.getMonth() + 1, -11)
    console.log(newDate);
    
    return [{
      id: 1,
      url: "",
      title: "Meeting",
      start: "2024-04-03T11:01:24.000000Z",
      end: "2024-04-03T13:01:24.000000Z",
      allDay: false,
      extendedProps: {
          calendar: "Business"
      }
  },
  {
    id: 2,
    url: "",
    title: "Meeting",
    start: "2024-04-03T11:01:24.000000Z",
    end: "",
    allDay: false,
    extendedProps: {
        calendar: "Holiday"
    }
},
{
  id: 3,
  url: "",
  title: "Meeting",
  start: "2024-04-03T11:01:24.000000Z",
  end: "",
  allDay: false,
  extendedProps: {
      calendar: "Family"
  }
},
{
  id: 4,
  url: "",
  title: "Meeting",
  start: "2024-04-03T11:01:24.000000Z",
  end: "",
  allDay: false,
  extendedProps: {
      calendar: "ETC"
  }
},
{
  id: 5,
  url: "",
  title: "Meeting",
  start: "2024-04-03T11:01:24.000000Z",
  end: "",
  allDay: false,
  extendedProps: {
      calendar: "Personal"
  }
},
];
  }
);

export const addEvent = createAsyncThunk(
  "appCalendar/addEvent",
  async (event, { dispatch, getState }) => {
    console.log(event);
    const config = {
      method: "post",
      url: `${REST_API_WORKSUITE_V3}events`,
      headers: {
        Authorization: token,
        "X-WorkSuite-Company-Token": token_company,
      },
      data: event,
    };
    try {
      const response = await axios(config);
      // await axios.post("/apps/calendar/add-event", { event });

      await dispatch(fetchEvents(getState().calendar.selectedCalendars));
      return toast.success(response.data.message);
      // const res = await axios(config);
      // return res.data;
    } catch (error) {
      throw new Error(error);
    }
    // const response = await axios(config)
    //   await axios.post('/apps/calendar/add-event', { event })

    //   await dispatch(fetchEvents(getState().calendar.selectedCalendars))
    //   return response
  }
);

export const updateEvent = createAsyncThunk(
  "appCalendar/updateEvent",
  async (event, { dispatch, getState }) => {
    await axios.post("/apps/calendar/update-event", { event });
    await dispatch(fetchEvents(getState().calendar.selectedCalendars));
    return event;
  }
);

export const updateFilter = createAsyncThunk(
  "appCalendar/updateFilter",
  async (filter, { dispatch, getState }) => {
    console.log(getState());
    if (getState().calendar.selectedCalendars.includes(filter)) {
      await dispatch(
        fetchEvents(
          getState().calendar.selectedCalendars.filter((i) => i !== filter)
        )
      );
    } else {
      await dispatch(
        fetchEvents([...getState().calendar.selectedCalendars, filter])
      );
    }
    return filter;
  }
);

export const updateAllFilters = createAsyncThunk(
  "appCalendar/updateAllFilters",
  async (value, { dispatch }) => {
    // if (value === true) {
    await dispatch(
      fetchEvents(["Personal", "Business", "Family", "Holiday", "ETC"])
    );
    // } else {
    // await dispatch(fetchEvents([]))
    // }
    return value;
  }
);

export const removeEvent = createAsyncThunk(
  "appCalendar/removeEvent",
  async (id) => {
    await axios.delete("/apps/calendar/remove-event", { id });
    return id;
  }
);

export const appCalendarSlice = createSlice({
  name: "appCalendar",
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCalendars: ["Personal", "Business", "Family", "Holiday", "ETC"],
    loading: false,
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          state.selectedCalendars.splice(
            state.selectedCalendars.indexOf(action.payload),
            1
          );
        } else {
          state.selectedCalendars.push(action.payload);
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload;
        let selected = [];
        if (value === true) {
          selected = ["Personal", "Business", "Family", "Holiday", "ETC"];
        } else {
          selected = [];
        }
        state.selectedCalendars = selected;
      });
  },
});

export const { selectEvent } = appCalendarSlice.actions;

export default appCalendarSlice.reducer;
