/* eslint-disable */
import axios from "axios";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import { getUserData } from "../../../auth/utils";
import { accessToken } from "../../../views/apps/ecommerce/utils/utils";
import * as Constants from "./../../../constants";
import jwtDefaultConfig from "./jwtDefaultConfig";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          // config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if status === 401 call api refresh token and update token in local storage
        if (
          response &&
          response.status === 401 &&
          response.data.error.message === "Unauthorized."
        ) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;

            this.refreshToken()
            .then((r) => {
              this.isAlreadyFetchingAccessToken = false;

              const userData = getUserData();
              userData.data.token = r.data.data.access_token;

              // ** Update accessToken in localStorage
              this.setToken(userData);

              // If method is get we need to reload the page to update the token and data
              // if (config.method === "get") {
              //   window.location.reload();
              // }

              // ** When the refresh is successful, replace the expired token and retry the original request
              config.headers[
                "Authorization"
              ] = `${this.jwtConfig.tokenType} ${r.data.data.access_token}`;
              axios(config);

              this.onAccessTokenFetched(r.data.data.access_token);
            }).catch((err) => {
              // ** If there is an error in the refresh, logout the user
              toast.error("Your session has expired, please login again");
              localStorage.clear();
              window.location.href = "/login";
            });

          } else if (
            response &&
            response.status === 401 &&
            response.data.error.message === "Token has been blacklisted"
          ) {
            toast.error("Your session has expired, please login again");
            localStorage.clear();
            window.location.href = "/login";
          }

          // if (response && response.status === 401) {
          //   // ** If the token is expired, logout the user
          //   toast.error("Your session has expired, please login again");
          //   localStorage.clear();
          //   window.location.href = "/login";
          // }

          // const retryOriginalRequest = new Promise(resolve => {
          //   this.addSubscriber(accessToken => {
          //     // ** Make sure to assign accessToken according to your response.
          //     // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          //     // ** Change Authorization header
          //     // originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          //     resolve(this.axios(originalRequest))
          //   })
          // })
          // console.log(retryOriginalRequest);
          // return retryOriginalRequest
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getJWT() {
    return localStorage.getItem(this.jwtConfig.storageUserData);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem("userData", JSON.stringify(value));
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  async login(...args) {
    const jwtConfig = {
      secret: "dd5f3089-40c3-403d-af14-d0c228b05cb4",
      refreshTokenSecret: "7c4c1c50-3230-45bf-9eae-c9b2e401c767",
      expireTime: "10m",
      refreshTokenExpireTime: "10m",
    };

    let errors = {
      email: ["Something went wrong"],
    };

    let user_sp = {};
    const axios = require("axios");
    const qs = require("qs");
    const data2 = qs.stringify({
      email: args[0].email,
      password: args[0].password,
    });

    const config = {
      method: "post",
      url: `${Constants.REST_API_WORKSUITE_V3}accounts/sign-in`,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      data: data2,
    };
    try {
      const response = await axios(config);
      user_sp = response.data;
      if (user_sp.error) {
        errors = {
          email: [user_sp.error.message],
        };
      } else {
        if (user_sp.data.user) {
          const user_id = user_sp.data.user.id;
          user_sp.id = user_id;
          user_sp.fullName = user_sp.data.user.name;
          user_sp.username = user_sp.data.user.name;
          user_sp.password = "";
          user_sp.avatar = user_sp.data.user.image_url;
          user_sp.email = user_sp.data.user.email;
          user_sp.role = "client";
          user_sp.ability = [
            {
              action: "read",
              subject: "ACL",
            },
            {
              action: "read",
              subject: "Auth",
            },
          ];
          user_sp.extras = {
            eCommerceCartItemsCount: 0,
          };

          const accessToken = jwt.sign({ id: user_id }, jwtConfig.secret, {
            expiresIn: jwtConfig.expireTime,
          });

          const refreshToken = jwt.sign(
            { id: user_id },
            jwtConfig.refreshTokenSecret,
            { expiresIn: jwtConfig.refreshTokenExpireTime }
          );

          const userData = { ...user_sp };
          delete userData.password;

          const result = {
            userData,
            accessToken,
            refreshToken,
          };

          return [200, result];
        } else {
          errors = {
            email: ["Email or Password is Invalid"],
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [400, { errors }];
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    const token = accessToken();

    return axios.post(
      `${Constants.REST_API_WORKSUITE_V3}accounts/refresh-token`,
      {},
      { headers: { Authorization: token } }
    );
  }
}
