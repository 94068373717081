import { Circle, Clipboard, FileText } from "react-feather";
import { toast } from "react-toastify";
import imgDocument from "../../../../assets/images/imgFiles/imgDocument.png";
import imgExcell from "../../../../assets/images/imgFiles/imgExcell.png";
import imgPdf from "../../../../assets/images/imgFiles/imgPdf.png";
import imgPowerPoint from "../../../../assets/images/imgFiles/imgPowerPoint.png";

export const converArrString = (str) => {
  const newString = [];
  const findChar = (str) => {
    for (var i = 0; i < str?.length; i++) {
      const charAtString = str.charAt(i);
      if (charAtString === " ") {
        newString.push(str?.charAt(0) + str?.charAt(i + 1));
      }
    }
    return newString[0];
  };
  findChar(str);
  if (newString.length !== 0) {
    return newString[0];
  } else {
    newString.push(str?.slice(0, 2));
    return newString[0];
  }
};

export const randomColor = (index) => {
  const arr = ["primary", "success", "warning", "secondary", "info"];
  if (index < 15) {
    switch (index) {
      case 0: {
        return arr[0];
      }
      case 1: {
        return arr[1];
      }
      case 2: {
        return arr[2];
      }
      case 3: {
        return arr[3];
      }
      case 4: {
        return arr[4];
      }
      case 5: {
        return arr[3];
      }
      case 6: {
        return arr[3];
      }
      case 7: {
        return arr[2];
      }
      case 8: {
        return arr[1];
      }
      case 9: {
        return arr[0];
      }
      case 10: {
        return arr[3];
      }
      case 11: {
        return arr[4];
      }
      case 12: {
        return arr[1];
      }
      case 13: {
        return arr[2];
      }
      case 14: {
        return arr[0];
      }
      default:
        throw new Error("Invalid");
    }
  } else {
    return arr[0];
  }
  // return arr[Math.floor(Math.random() * arr.length)];
};

export const momentFormat = () => {
  const user = JSON.parse(localStorage?.getItem("userData"));
  // const moment_format = user?.data?.user?.moment_format;
  const moment_format = "YYYY-MM-DD";
  return moment_format;
};

export const roleUser = () => {
  const roleUser = localStorage?.getItem("last_role_use_login");
  return roleUser;
};

export const typeLayout = () => {
  return JSON.parse(localStorage.getItem("skin"));
};

export const accessToken = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const token = `Bearer ${user?.data?.token}`;
  return token;
};

export const tokenCompany = () => {
  const token_company = localStorage?.getItem("token_company");
  return token_company;
};

export const tokenAcountDrives = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const sliceDrive = user?.data?.drive?.split("!");
  const tokenAcountDrive = `Bearer ${sliceDrive?.[0]}`;
  return tokenAcountDrive;
};

export function handleRequestSuccess(res) {
  if (res.data.message) {
    return toast.success(res.data.message);
  }
}

export function handleRequestErrors(err) {
  switch (err.response?.status) {
    case 400: {
      const keyField = Object.keys(err.response?.data.error);
      keyField?.map((field) => {
        return toast.error(err.response?.data.error[`${field}`][0]);
      });
    }
    case 403: {
      return toast.warning(err.response?.data.error.message);
    }
    case 401:
    case 404:
    case 405:
    case 500: {
      return toast.error(err.response?.data.error.message);
    }
    default: {
      break;
    }
  }
}

export const checkModuleSetting = (nameModule, nameLink) => {
  const role = roleUser();
  const listModule = JSON?.parse(localStorage.getItem("listModule"));
  const module = listModule?.find(
    (item) => nameModule === item.name && role === item.type
  );

  if (module?.status === "active") {
    return {
      id: module?.name,
      title: module?.name,
      icon: <Circle size={12} />,
      navLink: `/apps/${nameLink}/all-${module?.name}`,
      action: "read",
      resource: "ACL",
    };
  } else {
    return {
      id: "",
      title: "",
      icon: "",
      navLink: "",
      action: "",
      resource: "",
    };
  }
};
export const checkModuleSettingTickets = (nameModule, nameLink) => {
  const role = roleUser();
  const listModule = JSON?.parse(localStorage.getItem("listModule"));
  // if(listModule === null) {
  //   location.reload()
  // }
  const module = listModule?.find(
    (item) => nameModule === item.name && role === item.type
  );

  if (module?.status === "active") {
    return {
      id: "engagement",
      title: "Engagement",
      icon: <Circle size={12} />,
      navLink: `/apps/${nameLink}/all-${module?.name}`,
      action: "read",
      resource: "ACL",
    };
  } else {
    return {
      id: "",
      title: "",
      icon: "",
      navLink: "",
      action: "",
      resource: "",
    };
  }
};
export const checkModuleSettingKnowledge = (nameModule, nameLink) => {
  const role = roleUser();
  const listModule = JSON?.parse(localStorage.getItem("listModule"));
  const module = listModule?.find(
    (item) => nameModule === item.name && role === item.type
  );

  if (module?.status === "active") {
    return {
      id: "Knowledge",
      title: "Knowledge",
      icon: <FileText size={10} />,
      navLink: `/apps/${nameLink}/all-knowledge`,
      action: "read",
      resource: "ACL",
    };
  } else {
    return {
      id: "",
      title: "",
      icon: "",
      navLink: "",
      action: "",
      resource: "",
    };
  }
};
export const checkModuleSettingLeads = (nameModule) => {
  const role = roleUser();
  const listModule = JSON?.parse(localStorage.getItem("listModule"));
  const module = listModule?.find(
    (item) => nameModule === item.name && role === item.type
  );

  if (module?.status === "active") {
    return {
      id: module?.name,
      title: module?.name,
      icon: <Clipboard size={10} />,
      navLink: `/apps/all-${module?.name}`,
      action: "read",
      resource: "ACL",
    };
  } else {
    return {
      id: "",
      title: "",
      icon: "",
      navLink: "",
      action: "",
      resource: "",
    };
  }
};
export const checkModuleSettingProducts = (nameModule) => {
  const role = roleUser();
  const listModule = JSON?.parse(localStorage.getItem("listModule"));
  const module = listModule?.find(
    (item) => nameModule === item.name && role === item.type
  );

  if (module?.status === "active") {
    return {
      id: module?.name,
      title: module?.name,
      icon: <Circle size={10} />,
      navLink: `/apps/settings/all-${module?.name}`,
      action: "read",
      resource: "ACL",
    };
  } else {
    return {
      id: "",
      title: "",
      icon: "",
      navLink: "",
      action: "",
      resource: "",
    };
  }
};
export const checkModuleSettingAdmin = () => {
  const role = roleUser();
  if (role !== "employee" || role !== "client") {
    return {
      id: "module-settings",
      title: "Module Settings",
      icon: <Circle size={12} />,
      navLink: `/apps/settings/module-settings`,
      action: "read",
      resource: "ACL",
    };
  } else {
    return {
      id: "",
      title: "",
      icon: "",
      navLink: "",
      action: "",
      resource: "",
    };
  }
};
export const checkModuleSettingNotification = () => {
  const role = roleUser();
  if (role !== "employee" || role !== "client") {
    return {
      id: "notification",
      title: "Notification",
      icon: <Circle size={12} />,
      navLink: `/apps/settings/notification`,
      action: "read",
      resource: "ACL",
    };
  } else {
    return {
      id: "",
      title: "",
      icon: "",
      navLink: "",
      action: "",
      resource: "",
    };
  }
};
export const checkModuleAdminSetup = () => {
  const role = roleUser();
  if (role !== "employee" || role !== "client") {
    return {
      id: "setup-wizard",
      title: "Setup Wizard",
      icon: <Circle size={12} />,
      navLink: `/apps/dashboard/admin-setup`,
      action: "read",
      resource: "ACL",
    };
  } else {
    return {
      id: "",
      title: "",
      icon: "",
      navLink: "",
      action: "",
      resource: "",
    };
  }
};
export const checkModuleSettingRole = () => {
  const role = roleUser();
  if (role !== "employee" || role !== "client") {
    return {
      id: "Roles-&-Permissions",
      title: "Roles & Permissions",
      icon: <Circle size={12} />,
      navLink: "/apps/settings/role-permissions",
      action: "read",
      resource: "ACL",
    };
  } else {
    return {
      id: "",
      title: "",
      icon: "",
      navLink: "",
      action: "",
      resource: "",
    };
  }
};

export function renderFilePreview(type) {
  switch (type) {
    case "pdf": {
      return (
        <img
          className="rounded"
          alt={"image"}
          src={imgPdf}
          height="40"
          width="40"
        />
      );
    }
    case "word": {
      return (
        <img
          className="rounded"
          alt={"image"}
          src={imgDocument}
          height="40"
          width="40"
        />
      );
    }
    case "spreadsheet": {
      return (
        <img
          className="rounded"
          alt={"image"}
          src={imgExcell}
          height="40"
          width="40"
        />
      );
    }
    case "powerPoint": {
      return (
        <img
          className="rounded"
          alt={"image"}
          src={imgPowerPoint}
          height="40"
          width="40"
        />
      );
    }
    case "image": {
      return <Image size="28" />;
    }
    default:
      return <FileText size="28" />;
  }
}

export const renderFileSize = (size) => {
  if (Math.round(size / 100) / 10 > 1000) {
    return `${(Math.round(size / 100) / 10000).toFixed(1)} MB`;
  } else {
    return `${(Math.round(size / 100) / 10).toFixed(1)} KB`;
  }
};

export const addParamIfExist = (searchParams, paramName, paramValue) => {
  if (paramValue !== "") {
    return searchParams.set(paramName, paramValue);
  }
};

export const timezone = () => {
  const company = localStorage.getItem("company");
  const timezone = JSON.parse(company)?.timezone;
  return timezone;
}

export const time_format = () => {
  const company = localStorage.getItem("company");
  const time_format = JSON.parse(company)?.time_format;
  return time_format;
}

export const date_format = () => {
  const company = localStorage.getItem("company");
  const date_format = JSON.parse(company)?.date_format;
  return date_format;
}
